.collection-description-popup {
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-header {
      border-bottom: 1px solid #f0f0f0;

      .ant-modal-title {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .ant-modal-body {
      .collection-description-content {
        max-height: 500px;
        overflow-y: auto;
        line-height: 1.6;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 16px;
        }

        p {
          margin-bottom: 14px;
        }

        ul,
        ol {
          padding-left: 20px;
          margin-bottom: 14px;
        }

        img {
          max-width: 100%;
          height: auto;
          margin: 16px 0;
        }
      }
    }

    .ant-modal-footer {
      padding: 4px 4px;
      border-top: 1px solid #f0f0f0;

      button {
        border-radius: 4px;
      }
    }
  }
}

@media (max-width: 768px) {
  .collection-description-popup {
    .ant-modal-content {
      margin: 0 12px;

      .collection-description-content {
        max-height: 400px;
      }
    }
  }
}
